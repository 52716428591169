define("discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker-filter", ["exports", "@ember/component", "@ember/helper", "@ember/modifier", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _helper, _modifier, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      role="button"
      class="location-picker-row"
      {{on "click" (fn @onCheck @filter)}}
    >
      <Input @checked={{@filter.checked}} @type="checkbox" />
      {{@filter.value}}
    </div>
  
  */
  {
    "id": "HB9wwxw4",
    "block": "[[[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[24,0,\"location-picker-row\"],[4,[32,0],[\"click\",[28,[32,1],[[30,1],[30,2]],null]],null],[12],[1,\"\\n    \"],[8,[32,2],null,[[\"@checked\",\"@type\"],[[30,2,[\"checked\"]],\"checkbox\"]],null],[1,\"\\n    \"],[1,[30,2,[\"value\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@onCheck\",\"@filter\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker-filter.js",
    "scope": () => [_modifier.on, _helper.fn, _component.Input],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});